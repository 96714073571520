import React, { useEffect, useState } from 'react';
import './Header.css'

const Header=()=>{

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    useEffect(() => {
        const fName = localStorage.getItem('first_name');
        const lName = localStorage.getItem('last_name');
        setFirstName(fName);
        setLastName(lName);
    }, []);
    return(
        <div className="headerContainer">
            <h3>Yangi<span>Export</span>.uz</h3>

            <div>
                <p>{firstName} {lastName}</p>
            </div>
        </div>
    )
}

export default Header;