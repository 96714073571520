import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import ProtectedRoute from './components/ProtectedRoute';

// Lazy load components
const Login = lazy(() => import('./components/Login'));
const HomeRoute = lazy(() => import('./components/navigations/Home'));
const CreateUser = lazy(() => import('./components/navigations/CreateUser'));
const AllUsers = lazy(() => import('./components/navigations/AllUsers'));
const Main = lazy(() => import('./client/main'));
const NotFounds = lazy(() => import('./components/navigations/NotFounds'));
const NavEdit = lazy(() => import('./components/navigations/NavEdit'));

const App = () => {
  return (
    <Router>
      <div className='app-container'>
        <Header />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/dashboard" element={<ProtectedRoute element={<HomeRoute />} />} />
            <Route path="/admin/createuser" element={<ProtectedRoute element={<CreateUser />} />} />
            <Route path="/admin/allusers" element={<ProtectedRoute element={<AllUsers />} />} />
            <Route path="/admin/user/edit/:username" element={<ProtectedRoute element={<NavEdit />} />} />
            <Route path=":username" element={<Main />} />
            <Route path="*" element={<NotFounds />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
